/**
 * @license
 * @copyright Copyright Motili Inc., 2023 All Rights Reserved
 */

export interface InvoiceRowType {
    [key: string]: any;
    invoiceNumber: string;
    fileName: string;
    dueDate: string;
    invoiceDate: string;
    clientId: number;
    orderedOnBehalfClientId?: number;
    orderedOnBehalfClient?: {
        id: number;
        name: string;
    };
    updated: string;
    downloaded: boolean;
    amountTotal: number;
    missingSerial: string | null;
    invoiceType: string;
    showClient: boolean;
    fileUpdate: boolean;
    poNumber: string;
    customerPoNumber: string;
    terms: string;
    reference1: string;
    reference2: string;
    billToName: string;
    billToAddress1: string;
    billToAddress2: string;
    billToCity: string;
    billToState: string;
    billToZip: string;
    shipToName: string;
    shipToAddress1: string;
    shipToAddress2: string;
    shipToCity: string;
    shipToState: string;
    shipToZip: string;
    state: string;
    adjustmentNumber: string | null;
    currency: string;
    id: number;
    haveLogs: boolean;
}

export enum INVOICE_PROPERTY {
    SALES_INVOICE = 'salesInvoice',
    PURCHASE_INVOICE = 'purchaseInvoice',
}

interface InvoicePropertyDisplayType {
    property:
        | INVOICE_PROPERTY.SALES_INVOICE
        | INVOICE_PROPERTY.PURCHASE_INVOICE;
    name: string;
    display: string;
}

export interface InvoiceTypeProperty {
    [key: string]: InvoicePropertyDisplayType;
}

export const invoiceTypesProperties: InvoiceTypeProperty = {
    sales: {
        property: INVOICE_PROPERTY.SALES_INVOICE,
        name: 'SALES INVOICE',
        display: 'Sales Invoice',
    },
    purchase: {
        property: INVOICE_PROPERTY.PURCHASE_INVOICE,
        name: 'PURCHASE INVOICE',
        display: 'Purchase Invoice',
    },
};

export interface SelectedInvoice {
    display: string;
    id: string;
}

export interface Term {
    id: string;
    display: string;
    days: number;
}
